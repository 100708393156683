import React, { useEffect, useState, useRef } from "react";
import classes from "./Common.module.css";
import ProfileCard from "./ProfileCard";
import Loader from "../../../../../GlobleShared/Loader/Loader";
import ConnectionService from "../../../../../../Services/ConnectionService";
import ConfirmationModal from "../../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import { toast } from "react-toastify";
import useDebounce from "../../../../../GlobleShared/CustomHooks/useDebounce";

const SentComponents = ({searchInput, isSearch, setIsSearch}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [profileDataList, setProfileDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setsearchTerm] = useState("");
  const [paginated, setpaginated] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [withdrawButtonText, setwithdrawButtonText] = useState("");
  const [modalConfirmationText, setmodalConfirmationText] = useState("");
  const [connectionId, setConnectionId] = useState();
  const [connectionStatusId, setconnectionStatusId] = useState();
  const [totalCount, setTotalCount] = useState();
  const initialized = useRef(false);
  const debouncedSearchInput = useDebounce(searchInput, 300);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      getConnectionsProfileData(page);
    }

    if (debouncedSearchInput.length >= 3) {
      refreshConnectionsProfileData(page, debouncedSearchInput)
    } 
    else if (debouncedSearchInput.length <= 2 && isSearch) {
      refreshConnectionsProfileData(page, "");
      setIsSearch(false);
    }
  }, [debouncedSearchInput]);

  const getConnectionsProfileData = (pageNum) => {
    let data = {
      limit: limit,
      page: pageNum,
      paginated: paginated,
      searchTerm: "",
    };
    setIsLoading(true);
    ConnectionService.sentInvitationList(data)
      .then((res) => {
        setProfileDataList([...profileDataList,...res.list]);
        setTotalCount(res?.totalCount)
        setIsLoading(false)
      })
      .catch((error) => {
        console.log('error: ', error);
        setIsLoading(false)
      });
  }

  const refreshConnectionsProfileData = (pageNum, searchInput) => {
    let data = {
      limit:limit*pageNum,
      page: 1,
      paginated: paginated,
      searchTerm: searchInput,
    };
    setIsLoading(true);
    ConnectionService.sentInvitationList(data)
      .then((res) => {
        setProfileDataList([...res.list]);
        setTotalCount(res.totalCount);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log('error: ', error);
        setIsLoading(false)
      });
  }

  const handleWithdraw = (connectionId, statusId) => {
    setConnectionId(connectionId);
    setconnectionStatusId(statusId);
    setwithdrawButtonText("Withdraw");
    setmodalConfirmationText("Are you sure, you want to withdraw the selected contact?");
    setShowModal(true);
  }

  const closeModalHandler = () => {
    setShowModal(false);
    setwithdrawButtonText("");
    setmodalConfirmationText("");
  }

  const handleConfirmation = () => {
    let successMsg;
    let data = {
      connectionId: connectionId,
      connectionStatusId: connectionStatusId,
    };
    setIsLoading(true)
    ConnectionService.updateConnection(data)
      .then((res) => {
        setIsLoading(false);
        if(res?.message && connectionStatusId === 7){
          successMsg = "You have successfully withdrawn the invitation! It’s no longer active.";
        }
        toast.success(successMsg);
        refreshConnectionsProfileData(page);
        setShowModal(false);
      })
      .catch((error) => {
        console.log('error: ', error);
        setIsLoading(false);
        setShowModal(false);
      });
  }

  const viewMore = () => {
    getConnectionsProfileData(page +1);
    setPage(page +1);
  }

  return (
    <>
      {showModal && <ConfirmationModal
        onShow={handleWithdraw}
        onClose={closeModalHandler}
        getConfirmation={handleConfirmation}
        btnText={withdrawButtonText}
        confirmationText={modalConfirmationText}
      />
      }

<div className={classes.containers}>
        {profileDataList.length > 0 && profileDataList.map((profile) => (
          <ProfileCard
            key={profile.id}
            connectionsProfileDetails={profile}
            withdrawHandler={handleWithdraw}
            buttonShow={false}
          />
        ))}
      </div>
      {(totalCount > profileDataList?.length && profileDataList.length > 0) && (
          <div className="text-center mt-2 mb-2">
            <button onClick={viewMore} className={classes['view-more']}>View more</button>
          </div>
        )}

        {profileDataList.length === 0 && (
          <div className={classes["no-data"]}>
            <p>
              No Data Found
            </p>
          </div>
        )}
      {isLoading && (
        <Loader></Loader>
      )}
    </>
  );
};

export default SentComponents;

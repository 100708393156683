import { useEffect, useState } from "react";
import classes from "./NewResetPassword.module.css";
import leftImg from "../../../../Assets/Images/signin-page-img.svg";
import union from "../../../../Assets/Logo/Union.svg";
import Loader from "../../../GlobleShared/Loader/Loader";
import { useNavigate, useParams } from "react-router-dom";
import ApiService from "../../../../Services/APIService";
import { toast } from "react-toastify";
import OTPInput from "react-otp-input";

const inputStyle = {
  width: "52px",
  height: "68px",
  margin: "5px 10px 5px 0px",
  fontSize: "3em",
  borderRadius: 12,
  border: "2px solid #213C92",
  justifyContent: "center",
  fontWeight: "bolder",
  boxShadow: " 0px 4px 4px 0px #00000040",
};

const invalidOtpStyle = {
  width: "52px",
  height: "68px",
  margin: "5px 10px 5px 0px",
  fontSize: "3em",
  borderRadius: 12,
  border: "2px solid #E82529",
  justifyContent: "center",
  fontWeight: "bolder",
  boxShadow: " 0px 4px 4px 0px #00000040",
  color: "#E82529",
};

const NewResetPassword = () => {
  const [otpDetails, setOtpDetails] = useState(null);
const [otpEmailDetails, setOtpEmailDetails] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [tokenStatus, settokenStatus] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState("email");
  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(30);
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [otpSubmitted, setOtpSubmitted] = useState(false);
  const [otpVerificationPossible, setOtpVerificationPossible] = useState(false);
  const [passwordValidationErrors, setPasswordValidationErrors] = useState({});
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [visible, setVisible] = useState(false);
  const [otpType, setOtpType] = useState("email");

  const [userDetailId , setUserDetailId] = useState(0)

  const [form, setForm] = useState({
    email: "",
    confirmEmail: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    newPassword: "",
    confirmPassword: "",
  });

  const { userId, token } = useParams();
  const navigate = useNavigate();

  // useEffect(() => {
  //   // tokenValidation();
  // }, []);

  // const tokenValidation = () => {
  //   setisLoading(true);
  //   ApiService.checkToken(token)
  //     .then((res) => {
  //       if (res?.valid) {
  //         settokenStatus(true);
  //       }
  //       setisLoading(false);
  //     })
  //     .catch((error) => {
  //       settokenStatus(false);
  //       setisLoading(false);
  //     });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedOption(e.target.value);
    if (value === 'email') {
      setOtpType("email"); // Store email if email is selected
    } else if (value === 'phone') {
      setOtpType("phone"); // Store phone number if phone is selected
    }
  };

  const viewPass = () => {
    setVisible(!visible);
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const viewConfirmPass = () => {
    setVisibleConfirm(!visibleConfirm);
    setConfirmPasswordType(
      confirmPasswordType === "password" ? "text" : "password"
    );
  };
  const [formErrors, setFormErrors] = useState({
    email: null,
    confirmEmail: null,
    phoneNumber: null,
    firstName: null,
    lastName: null,
    newPassword: null,
    confirmPassword: null,
  });

  const validateField = (name, value, refValue) => {
    let errorMsg = null;
    switch (name) {
      case "email":
        if (!value) errorMsg = "Please enter Email.";
        else if (refValue && value !== refValue)
          errorMsg = "";
        break;
      case "confirmEmail":
        if (!value) errorMsg = "Please enter Confirm Email.";
        else if (refValue && value !== refValue)
          errorMsg = "Email and Confirm Email do not match.";
        break;
      case "phoneNumber":
        if (!value) errorMsg = "Please enter Phone Number.";
        else if (
          value.replace(/\D/g, "").length < 10 ||
          value.replace(/\D/g, "").length > 10
        )
          errorMsg = "Phone Number must be  10 digits.";
        break;
      case "newPassword":
        if (!value) errorMsg = "Please enter New Password.";
        else if (refValue && value !== refValue)
          errorMsg = "";
        break;
      case "confirmPassword":
        if (!value) errorMsg = "Please enter Confirm Password.";
        else if (refValue && value !== refValue)
          errorMsg = "Password and Confirm Password do not match.";
        break;
      default:
        break;
    }
    return errorMsg;
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;

    // Ensure phone number only contains numeric values
    const newValue = name === "phoneNumber" ? value.replace(/\D/g, "") : value;

    setForm((prevForm) => ({
      ...prevForm,
      [name]: newValue,
    }));

    if (formErrors[name] !== undefined) {
      const errorMsg = validateField(
        name,
        newValue,
        form[name === "newPassword" ? "confirmPassword" : "newPassword"]
      );
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [name]: errorMsg,
      }));
    }
// Validate email and confirm email fields immediately
  if (name === "email" || name === "confirmEmail") {
    const emailError = validateField("email", name === "email" ? newValue : form.email, form.confirmEmail);
    const confirmEmailError = validateField("confirmEmail", name === "confirmEmail" ? newValue : form.confirmEmail, form.email);
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      email: emailError,
      confirmEmail: confirmEmailError,
    }));
  } else {
    // Validate other fields
    if (formErrors[name] !== undefined) {
      const errorMsg = validateField(
        name,
        newValue,
        form[name === "newPassword" ? "confirmPassword" : "newPassword"]
      );
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [name]: errorMsg,
      }));
    }

    // Validate phone number field immediately
    if (name === "phoneNumber") {
      const phoneNumberError = validateField("phoneNumber", newValue);
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        phoneNumber: phoneNumberError,
      }));
    }

  // Validate password and confirm password fields immediately
  if (name === "newPassword" || name === "confirmPassword") {
    const newPasswordError = validateField("newPassword", name === "newPassword" ? newValue : form.newPassword, form.confirmPassword);
    const confirmPasswordError = validateField("confirmPassword", name === "confirmPassword" ? newValue : form.confirmPassword, form.newPassword);
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      newPassword: newPasswordError,
      confirmPassword: confirmPasswordError,
    }));

    // Validate password when changing password or confirm password
    const validationErrors = validatePassword(newValue);
    setPasswordValidationErrors(validationErrors);
  }

  // Validate other fields
  if (formErrors[name] !== undefined) {
    const errorMsg = validateField(
      name,
      newValue,
      form[name === "newPassword" ? "confirmPassword" : "newPassword"]
    );
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [name]: errorMsg,
    }));
  }

  };
  }
  
  // for getting mobile otp details
  const sendAuthOtp = (number) => {
    setisLoading(true);
    ApiService.getMobOtp(number)
      .then((res) => {
        setisLoading(false);
        setOtpDetails(res.data);
        // setVerified(false);

        countdown();
        setOtp("");
        setInvalidOtp(false);
      })
      .catch((error) => {
        setisLoading(false);
        toast.error(error?.message);
      });
  };

  
  // for validate mobile otp details
  const validateAuthOtp = (session, otp) => {
    setisLoading(true);
    ApiService.validateMobOtp(session, otp)
      .then((res) => {
        setisLoading(false);
        setCurrentStep(5);
        // setVerified(true);
        // otpSubmit();
        // countdown();
        setOtp("");
        setInvalidOtp(false);
      })
      .catch((error) => {
        setisLoading(false);
        setInvalidOtp(true);
        toast.error(error?.Details);
      });
  };
  

   // for getting email otp details
   const sendEmailAuthOtp = (email) => {
    setisLoading(true);
    const data = {
      source: email,
      sourceType: "email",
    };
    ApiService.getEmailOtp(data)
      .then((res) => {
        setisLoading(false);
        setOtpEmailDetails(res);
        // setVerified(false);

        countdown();
        setOtp("");
        setInvalidOtp(false);
      })
      .catch((error) => {
        setisLoading(false);
        toast.error(error?.message);
      });
  };


   // for validating email otp
const validateEmailAuthOtp = (session, otp) => {
  
  const data = {
    sessionId: session,
    authCode: otp,
  };
  setisLoading(true);
  ApiService.validateEmailOtp(data)
    .then((res) => {
      setisLoading(false);
      setCurrentStep(5);
      // setVerified(true);
      // otpSubmit();
      // countdown();
      setOtp("");
      setInvalidOtp(false);
    })
    .catch((error) => {
      setisLoading(false);
      setInvalidOtp(true);
      toast.error(error?.message);
    });
};
  
  // const sendEmailAuthOtp = async (email) => {
  //   setisLoading(true);
  //   try {
  //     const response = await ApiService.getEmailOtp({ email });
  //     setOtpEmailDetails(response.data);
  //     toast.success("OTP sent successfully.");
  //   } catch (error) {
  //     toast.error("Failed to send OTP.");
  //   } finally {
  //     setisLoading(false);
  //   }
  // };
  
  // const validateAuthOtp = async (session, otp) => {
  //   setisLoading(true);
  //   try {
  //     const response = await ApiService.validateMobOtp(session, otp);
  //     setOtpVerificationPossible(true);
  //     toast.success("OTP verified successfully.");
  //   } catch (error) {
  //     toast.error("Invalid OTP.");
  //   } finally {
  //     setisLoading(false);
  //   }
  // };
  
  // const validateEmailAuthOtp = async (session, otp) => {
  //   setisLoading(true);
  //   try {
  //     const response = await ApiService.validateEmailOtp({ session, otp });
  //     setOtpVerificationPossible(true);
  //     toast.success("OTP verified successfully.");
  //   } catch (error) {
  //     toast.error("Invalid OTP.");
  //   } finally {
  //     setisLoading(false);
  //   }
  // };
  

  const handleNext = async (e) => {
    // debugger
    e.preventDefault();



    if (currentStep === 1) {
      if (!form.email || !form.confirmEmail || !form.phoneNumber) {
        toast.error("Please fill in all fields.");
        return;
      }
      setisLoading(true);
      try {
        const userDetails = await ApiService.getUserDetails({
          email: form.email,
          mobileNumber: form.phoneNumber
        });

        setUserDetailId(userDetails?.userId);

        setForm({
          ...form,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName
        });
        setCurrentStep(2);
      } catch (error) {
        toast.error("Failed to retrieve user details.");
      } finally {
        setisLoading(false);
      }
    } 
    
    
    else if (currentStep === 2) {
      if (!form.firstName || !form.lastName) {
        toast.error("Please fill in all fields.");
        return;
      }
      setCurrentStep(3);
      
    } 
    
    
    else if (currentStep === 3) {
      if (form.email || form.phoneNumber) {
        if (otpType === "phone") {
          setOtp("");
          sendAuthOtp(form.phoneNumber);
        }
        else if(otpType === "email"){
          setOtp("");
          sendEmailAuthOtp(form.email);
        }
        countdown();
        setCurrentStep(4);
      }
    }
    
    else if (currentStep === 4 && otp.length === 6) {
      if (otpType === "phone") {
        validateAuthOtp(otpDetails?.Details, otp);

      }
      else if (otpType === "email") {
        validateEmailAuthOtp(otpEmailDetails?.response, otp);
      }
    } 
    
    else if (currentStep === 5) {
      if (!form.newPassword || !form.confirmPassword) {
        toast.error("Please fill in all fields.");
        return;
      }
      if (form.newPassword !== form.confirmPassword) {
        toast.error("Passwords do not match.");
        return;
      }
      setisLoading(true);
      try {
        await ApiService.resetPassword({
          password: form.newPassword,
          resetToken: "",
          userId: userDetailId,
        });
        toast.success("Password reset successfully.");
        navigate('/sign-in');
      } catch (error) {
        toast.error("Failed to reset password.");
      } finally {
        setisLoading(false);
      }
    }
  };


  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    } else {
      navigate("/forgot-password");
    }
  };

  const countdown = () => {
    let intervalId;
    let timeLeft = 30;
    clearInterval(intervalId);
    setTimeLeft(timeLeft);
    intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(intervalId);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  const resendOtp = () => {
    setOtp("");
    countdown();
    setInvalidOtp(false);
    if (otpType === "phone") {
      sendAuthOtp(form.phoneNumber);
    } else if (otpType === "email") {
      sendEmailAuthOtp(form.email);
    }
  };

  const getOtp = (otp) => {
    setInvalidOtp(false);
    setOtp(otp);
  };

  const maskEmail = (email) => {
    const [localPart, domain] = email.split("@");
    const maskedLocalPart = localPart[0] + "*".repeat(localPart.length - 1);
    return `${maskedLocalPart}@${domain}`;
  };

  
  const validatePassword = (password) => {
    const errors = {};
    const minLength = 8;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /\d/;
    const uppercaseRegex = /[A-Z]/;

    if (password.length < minLength) {
      errors.minLength = true;
    }
    if (!specialCharRegex.test(password)) {
      errors.specialChar = true;
    }
    if (!numberRegex.test(password)) {
      errors.number = true;
    }
    if (!uppercaseRegex.test(password)) {
      errors.uppercase = true;
    }

    return errors;
  };

  return (
    <div className={classes["main-container"]}>
      <div className={`${classes.row_hieght} row`}>
        <div className={`${classes.col_img} col-md-7`}>
          <div>
            <img className={classes["left-img"]} src={leftImg} alt="" />
          </div>
        </div>
        <div className={`${classes.right_col} col-md-5`}>
          <div className={classes["sign_up-box"]}>
            <div className={classes.header}>
              <span>
                <img src={union} alt="" />
              </span>
              <span>MSM Pursuit</span>
            </div>
            <span className={classes.header}>Reset Password</span>
            {/* {tokenStatus && ( */}
              <form>
                <div className={classes["form-section"]}>
                  <div className={classes["form-content"]}>
                    {currentStep === 1 && (
                      <>
                        <div className="form-group">
                          <label
                            htmlFor="email"
                            className={`${classes.label} form-label`}
                          >
                            Email
                          </label>
                          <input
                            onChange={changeHandler}
                            onBlur={changeHandler}
                            type="email"
                            placeholder="Type Email Here..."
                            name="email"
                            value={form.email}
                            className="form-control"
                            id="email"
                          />
                          {formErrors.email && (
                            <span className={classes.error}>
                              {formErrors.email}
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="confirmEmail"
                            className={`${classes.label} form-label`}
                          >
                            Confirm Email
                          </label>
                          <input
                            onChange={changeHandler}
                            onBlur={changeHandler}
                            type="email"
                            placeholder="Re-type Email Here..."
                            name="confirmEmail"
                            value={form.confirmEmail}
                            className="form-control"
                            id="confirmEmail"
                          />
                          {formErrors.confirmEmail && (
                            <span className={classes.error}>
                              {formErrors.confirmEmail}
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="phoneNumber"
                            className={`${classes.label} form-label`}
                          >
                            Phone Number
                          </label>
                          <input
                            onChange={changeHandler}
                            onBlur={changeHandler}
                            type="tel"
                            placeholder="Type Phone Number Here..."
                            name="phoneNumber"
                            value={form.phoneNumber}
                            className="form-control"
                            id="phoneNumber"
                            pattern="[0-9]*"
                          />
                          {formErrors.phoneNumber && (
                            <span className={classes.error}>
                              {formErrors.phoneNumber}
                            </span>
                          )}
                        </div>
                      </>
                    )}
                    {currentStep === 2 && (
                      <>
                        <div className="form-group">
                          <label
                            htmlFor="firstName"
                            className={`${classes.label} form-label`}
                          >
                            First Name
                          </label>
                          <input
                            onChange={changeHandler}
                            onBlur={changeHandler}
                            type="text"
                            placeholder="Type First Name Here..."
                            name="firstName"
                            value={form.firstName}
                            className="form-control"
                            id="firstName"
                          />
                          {formErrors.firstName && (
                            <span className={classes.error}>
                              {formErrors.firstName}
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="lastName"
                            className={`${classes.label} form-label`}
                          >
                            Last Name
                          </label>
                          <input
                            onChange={changeHandler}
                            onBlur={changeHandler}
                            type="text"
                            placeholder="Type Last Name Here..."
                            name="lastName"
                            value={form.lastName}
                            className="form-control"
                            id="lastName"
                          />
                          {formErrors.lastName && (
                            <span className={classes.error}>
                              {formErrors.lastName}
                            </span>
                          )}
                        </div>
                      </>
                    )}
                    {currentStep === 3 && (
                      <>
                        <div className={classes["authorization-container"]}>
                          <div>
                            <p className={classes["heading"]}>
                              Account Authorization:
                            </p>
                          </div>
                          <div className={classes["authorization-content"]}>
                            <div className={classes["authorization-option"]}>
                              <input
                                className={`${classes.radioInput}`}
                                type="radio"
                                id="email"
                                name="authorization"
                                value="email"
                                checked={selectedOption === "email"}
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="email"
                              >
                                <span>Email : </span>
                                <span>{maskEmail(form.email)}</span>
                              </label>
                            </div>
                            <div className={classes["authorization-option"]}>
                              <input
                                className={`${classes.radioInput}`}
                                type="radio"
                                id="phone"
                                name="authorization"
                                value="phone"
                                checked={selectedOption === "phone"}
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="phone"
                              >
                                <span>Phone : </span>
                                <span className="form-group">
                                  <input
                                    type="tel"
                                    className="form-control"
                                    name="phoneNumber"
                                    placeholder="Type phone number here..."
                                    value={form.phoneNumber}
                                    onChange={changeHandler}
                                    disabled={true}
                                  />
                                </span>
                              </label>
                            </div>
                          </div>
                          <p className={classes["verify-message"]}>
                            We will send you a code to verify your account and
                            to keep it secure.
                          </p>
                        </div>
                      </>
                    )}
                    {currentStep === 4 && (
                      <>
                        <div className={classes["otp_section"]}>
                          <div>
                            <p className={classes["heading"]}>
                              Account Authorization:
                            </p>
                          </div>
                          {/* <div className={classes.dashSeparator}>-</div> */}
                          <div className={classes["otp_input"]}>
                            <OTPInput
                              inputStyle={
                                invalidOtp ? invalidOtpStyle : inputStyle
                              }
                              value={otp}
                              onChange={getOtp}
                              numInputs={6}
                              renderSeparator={<span></span>}
                              renderInput={(props) => (
                                <input
                                  className={classes["otp_Input"]}
                                  {...props}
                                />
                              )}
                            />
                          </div>
                          <div>
                            {invalidOtp && (
                              <p className={classes["timer"]}>
                                Wrong code. Please try again
                              </p>
                            )}
                          </div>
                          <div>
                            {timeLeft > 0 ? (
                              <p className={classes["timer"]}>
                                {timeLeft} seconds
                              </p>
                            ) : null}
                          </div>
                          <div class="mt-4">
                            <button
                              onClick={resendOtp}
                              type="button"
                              disabled={timeLeft !== 0}
                              className={`${classes.primary_button} primary-button`}
                            >
                              Resend Code
                            </button>
                          </div>
                          <div>
                            <p
                              onClick={handleBack}
                              className={classes["change_auth"]}
                            >
                              Change Authentication Device
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                    {currentStep === 5 && (
                      <>
                        <div className="form-group">
                          <label
                            htmlFor="newPassword"
                            className={`${classes.label} form-label`}
                          >
                            Password
                          </label>
                          <div className={classes["input-with-icon"]}>
                            <input
                              onChange={changeHandler}
                              onBlur={changeHandler}
                              type={passwordType}
                              placeholder="Type New Password Here..."
                              name="newPassword"
                              value={form.newPassword}
                              className="form-control"
                              id="newPassword"
                            />
                            {form.newPassword && (
                              <div className={classes["icon-container"]}>
                                <span
                                  className={classes["eyeicon"]}
                                  onClick={viewPass}
                                >
                                  <i
                                    className={`fa ${
                                      visible ? "fa-eye" : "fa-eye-slash"
                                    }`}
                                  />
                                </span>
                              </div>
                            )}
                          </div>
                          {formErrors.newPassword && (
                            <span className={classes.error}>
                              {formErrors.newPassword}
                            </span>
                          )}
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="confirmPassword"
                            className={`${classes.label} form-label`}
                          >
                            Confirm Password
                          </label>
                          <div className={classes["input-with-icon"]}>
                            <input
                              onChange={changeHandler}
                              onBlur={changeHandler}
                              type={confirmPasswordType}
                              placeholder="Re-type Password Here..."
                              name="confirmPassword"
                              value={form.confirmPassword}
                              className="form-control"
                              id="confirmPassword"
                            />
                            {form.confirmPassword && (
                              <div className={classes["icon-container"]}>
                                <span
                                  className={classes["eyeicon"]}
                                  onClick={viewConfirmPass}
                                >
                                  <i
                                    className={`fa ${
                                      visibleConfirm ? "fa-eye" : "fa-eye-slash"
                                    }`}
                                  />
                                </span>
                              </div>
                            )}
                          </div>
                          {formErrors.confirmPassword && (
                            <span className={classes.error}>
                              {formErrors.confirmPassword}
                            </span>
                          )}
                        </div>
                        <div className={classes["password-validation"]}>
                          <p className={classes["validation-heading"]}>
                            Password must contain:
                          </p>
                          <ul className={classes["validation-item"]}>
                            {!form.newPassword ? (
                              <li>
                                <span>At least 8 characters</span>
                              </li>
                            ) : !passwordValidationErrors.minLength ? (
                              <li style={{ color: "green" }}>
                                <div className="row">
                                  <span className="col-9">
                                    At least 8 characters
                                  </span>
                                  <span className="col-3">
                                    <i className="fa fa-check" />
                                  </span>
                                </div>
                              </li>
                            ) : (
                              <li style={{ color: "red" }}>
                                <div className="row">
                                  <span className="col-9">
                                    At least 8 characters
                                  </span>
                                  <span className="col-3">
                                    <i className="fa fa-close" />
                                  </span>
                                </div>
                              </li>
                            )}
                            {!form.newPassword ? (
                              <li>
                                <span>At least 1 special character</span>
                              </li>
                            ) : !passwordValidationErrors.specialChar ? (
                              <li style={{ color: "green" }}>
                                <div className="row">
                                  <span className="col-9">
                                    At least 1 special character
                                  </span>
                                  <span className="col-3">
                                    <i className="fa fa-check" />
                                  </span>
                                </div>
                              </li>
                            ) : (
                              <li style={{ color: "red" }}>
                                <div className="row">
                                  <span className="col-9">
                                    At least 1 special character
                                  </span>
                                  <span className="col-3">
                                    <i className="fa fa-close" />
                                  </span>
                                </div>
                              </li>
                            )}
                            {!form.newPassword ? (
                              <li>
                                <span>At least 1 number</span>
                              </li>
                            ) : !passwordValidationErrors.number ? (
                              <li style={{ color: "green" }}>
                                <div className="row">
                                  <span className="col-9">
                                    At least 1 number
                                  </span>
                                  <span className="col-3">
                                    <i className="fa fa-check" />
                                  </span>
                                </div>
                              </li>
                            ) : (
                              <li style={{ color: "red" }}>
                                <div className="row">
                                  <span className="col-9">
                                    At least 1 number
                                  </span>
                                  <span className="col-3">
                                    <i className="fa fa-close" />
                                  </span>
                                </div>
                              </li>
                            )}
                            {!form.newPassword ? (
                              <li>
                                <span>At least 1 uppercase letter</span>
                              </li>
                            ) : !passwordValidationErrors.uppercase ? (
                              <li style={{ color: "green" }}>
                                <div className="row">
                                  <span className="col-9">
                                    At least 1 uppercase letter
                                  </span>
                                  <span className="col-3">
                                    <i className="fa fa-check" />
                                  </span>
                                </div>
                              </li>
                            ) : (
                              <li style={{ color: "red" }}>
                                <div className="row">
                                  <span className="col-9">
                                    At least 1 uppercase letter
                                  </span>
                                  <span className="col-3">
                                    <i className="fa fa-close" />
                                  </span>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      </>
                    )}
                    <div className="mt-2">
                    <button
  disabled={
    (currentStep === 1 &&
      (!form.email ||
        !form.confirmEmail ||
        !form.phoneNumber ||
        form.email !== form.confirmEmail ||
        Object.keys(formErrors).some((key) => formErrors[key]))) ||
    (currentStep === 2 &&
      (!form.firstName ||
        !form.lastName ||
        Object.keys(formErrors).some((key) => formErrors[key]))) ||
    (currentStep === 4 && otp.length < 6) ||
    (currentStep === 5 &&
      (!form.newPassword ||
        !form.confirmPassword ||
        form.newPassword !== form.confirmPassword ||
        Object.keys(formErrors).some((key) => formErrors[key]))) ||
    isLoading
  }
  className={`${classes.primary_button} primary-button`}
  onClick={handleNext}
>
  Next
</button>
                      {/* {currentStep > 1 && (
                        <button
                          className={`${classes.secondary_button} secondary-button mt-2`}
                          onClick={handleBack}
                        >
                          Back
                        </button>
                      )} */}
                    </div>
                  </div>
                </div>
              </form>
            {/* )} */}
            {/* {!tokenStatus && (
              <div className={classes["token-invalid"]}>
                <p>Invalid or expired token.</p>
              </div>
            )} */}
          </div>
        </div>
      </div>
      <div>{isLoading && <Loader />}</div>
    </div>
  );
};

export default NewResetPassword;

import classes from "./SignUp.module.css";
import { Link, useNavigate } from "react-router-dom";
import ApiService from "../../../../Services/APIService.jsx";
import { useEffect, useState } from "react";
import Loader from "../../../GlobleShared/Loader/Loader.jsx";
import { useBehaviorSubject } from "../../../GlobleShared/BehaviorSubject/BehaviorSubject.jsx";
import { toast } from "react-toastify";
import leftImg from "../../../../Assets/Images/signin-page-img.svg";
import union from "../../../../Assets/Logo/Union.svg";
import dropDown from "../../../../Assets/Logo/drop-down_icon.svg";
import upperDrop from "../../../../Assets/Logo/uper-arrow-icon.svg";
import OTPInput from "react-otp-input";
import countryFlag from "../../../../Assets/Images/countryFlag.png";
import backArrow from "../../../../Assets/Logo/back-arrow.svg";
import 'flag-icons/css/flag-icons.min.css';
const inputStyle = {
  width: "52px",
  height: "68px",
  margin: "5px 10px 5px 0px",
  fontSize: "3em",
  borderRadius: 12,
  border: "2px solid #213C92",
  justifyContent: "center",
  fontWeight: "bolder",
  boxShadow: " 0px 4px 4px 0px #00000040",
};

const invalidOtpStyle = {
  width: "52px",
  height: "68px",
  margin: "5px 10px 5px 0px",
  fontSize: "3em",
  borderRadius: 12,
  border: "2px solid #E82529",
  justifyContent: "center",
  fontWeight: "bolder",
  boxShadow: " 0px 4px 4px 0px #00000040",
  color: "#E82529",
};

function SignUp() {
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [currentStep, setCurrentStep] = useState(1);
  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(30);
  // State to hold password validation error
  const [passwordValidationErrors, setPasswordValidationErrors] = useState({});
  const [invalidOtp, setInvalidOtp] = useState();
  const [otpSubmitted, setOtpSubmitted] = useState(false);
  const [isCountryCodeOpen, setCountryCodeOpen] = useState(false);
  const [isGenderOpen, setGenderOpen] = useState(false);
  //  state for userDetails
  const GenderData = ["Male", "Female", "Others"];
  const [countryCode, setCountryCode] = useState([]);
  const { setisLoginUser } = useBehaviorSubject();
  // authorization logic to set bydefault the authorization is done by email
  const [authorizationMethod, setAuthorizationMethod] = useState("email");
  const [phoneNumber, setPhoneNumber] = useState("");

 

const [otpMethod, setOtpMethod] = useState("");
const [otpDetails, setOtpDetails] = useState({});
const [otpEmailDetails, setOtpEmailDetails] = useState({});
const [verified, setVerified] = useState(false);
const [otpVerificationPossible, setOtpVerificationPossible] = useState(false);
const [otpType, setOtpType] = useState("email");
const [selectedCountry, setSelectedCountry] = useState(null);
const [dropdownOpen, setDropdownOpen] = useState(false);


  const handleFocus = (setOpen) => () => {
    setOpen(true);
  };

  const handleBlur = (setOpen) => () => {
    setOpen(false);
  };
  const [form, setForm] = useState({
    fname: "",
    lname: "",
    email: "",
    contact: "",
    countryCode: "",
    gender: "",
    dob: "",
    password: "",
    cpassword: "",
  });

  // state for errors
  const [formErrors, setFormErrors] = useState({
    fname: null,
    lname: null,
    email: null,
    contact: null,
    countryCode: null,
    gender: null,
    dob: null,
    password: null,
    cpassword: null,
    phoneNumber: null
  });

  // Handler for input changes

  const changeHandler = (e) => {
    const { name, value } = e.target;

    // Sanitize input based on field type
    const sanitizedValue = 
      (name === 'fname' || name === 'lname') 
        ? value.replace(/[^a-zA-Z]/g, '') // Remove non-letter characters for names
        : (name === 'email') 
        ? value.replace(/\s/g, '')
        : (name === 'contact') 
        ? value.replace(/\D/g, '') // Keep only digits for contact number
        : value;

    // Update form state
    setForm((prevForm) => ({
      ...prevForm,
      [name]: sanitizedValue,
    }));

    // Real-time validation for relevant fields
    if (['fname', 'lname', 'email', 'contact', 'dob'].includes(name)) {
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [name]: validateField(name, sanitizedValue),
      }));
    }

    // Validate password and confirm password fields
    if (name === 'password' || name === 'cpassword') {
      const validationErrors = validatePassword(
        name,
        name === 'password' ? sanitizedValue : form.password, // Update only the password field
        name === 'cpassword' ? sanitizedValue : form.cpassword // Update only the confirm password field
      );

      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [name]: name === 'password' ? validationErrors.passwordError : validationErrors.cpasswordError
      }));
      setPasswordValidationErrors(validationErrors);
    }

    // if (name === "countryCode") {
    //     if (value === "91") {
    //         setOtpVerificationPossible(true);
    //         console.log(otpVerificationPossible);
    //     } 
    //     else{
    //       setOtpVerificationPossible(false);
    //     }
    // }
  };

  // Function to validate individual fields
  const validateField = (name, value) => {
    let errorMsg = null;

    switch (name) {
      case 'fname':
      case 'lname':
        if (!value) errorMsg = `Please enter ${name === 'fname' ? 'first' : 'last'} name`;
        else if (/[^a-zA-Z]/.test(value)) errorMsg = 'Please enter a valid name';
        break;

      case 'contact':
      case 'phoneNumber':
        if (!value) errorMsg = 'Please enter contact number';
        else if ((value.replace(/\D/g, '').length) < 8 || (value.replace(/\D/g, '').length) > 10)
          errorMsg = 'Contact Number Should be between 8 and 10 digits';
        break;

      case 'email':
        if (!value) errorMsg = 'Please enter email';
        else if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
        )
          errorMsg = 'Please enter a valid email';
        break;

      default:
        break;
    }

    return errorMsg;
  };

  // Function to validate password requirements {some optimization is remaining for this}
  const validatePassword = (name, password, cpassword) => {
    const errors = {
      passwordError: null,
      cpasswordError: null,
      minLength: password.length < 8,
      specialChar: !/[!@#$%^&*(),.?":{}|<>]/.test(password),
      number: !/\d/.test(password),
      uppercase: !/[A-Z]/.test(password),
    };

    switch (name) {
      case 'password':
        if (!password) errors.passwordError = 'Please enter a password';
        break;

      case 'cpassword':
        if (!cpassword) errors.cpasswordError = 'Please enter confirm password';
        else if (password !== cpassword)
          errors.cpasswordError = 'Passwords do not match';
        break;

      default:
        break;
    }

  return errors;
};

  // Function to validate the entire form
  const validateForm = (form) => {
    const errorObj = {};
  
    Object.keys(form).forEach((key) => {
      const value = form[key];
      const errorMsg = validateField(key, value);
      
      if (key === 'password' || key === 'cpassword') {
        const passwordErrors = validatePassword(form.password, form.cpassword);
        if (passwordErrors.passwordError) errorObj.password = passwordErrors.passwordError;
        if (passwordErrors.cpasswordError) errorObj.cpassword = passwordErrors.cpasswordError;
      }
  
      if (errorMsg) {
        errorObj[key] = errorMsg;
      }
    });
  
    return errorObj;
  };

  const handleSubmit = () => {
    const errors = validateForm(form);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      // setIsSubmit(true);
      const formData = {
        emailId: form.email,
        firstName: form.fname,
        lastName: form.lname,
        mobileNoCountryCode: form.countryCode,
        contactNumber: form.contact,
        gender: form.gender,
        dateOfBirth: form.dob,
        password: form.password,
        // profilePic: "",
        userLoginType: 1
      };

      setisLoading(true);
      ApiService.userRegistration(formData)
        .then((res) => {
          setisLoading(false);
          toast.success(res?.message);
          handleLogin();
        })
        .catch((error) => {
          setisLoading(false);
          toast.error(error?.message);
        });
    }
  };

  const handleLogin = () => {
    const errors = validateForm(form);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setisLoading(true);
      const formData = {
        email: form.email,
        "ipAddress": "0.0.0.0",
        "loginType": 1,
        password: form.password,
      }
      ApiService.signInV1(formData)
        .then((res) => {
          localStorage.setItem("token", res?.token);
          localStorage.setItem("userDetail", JSON.stringify(res?.internalUserResponse));
          setisLoginUser(true);
          setisLoading(false);
          navigate("/member/profile");
        })
        .catch((error) => {
          setisLoading(false);
          toast.error(error?.message);
        });
    }
  };

  const viewPass = () => {
    setVisible(!visible);
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const viewConfirmPass = () => {
    setVisibleConfirm(!visibleConfirm);
    setConfirmPasswordType(
      confirmPasswordType === "password" ? "text" : "password"
    );
  };
  const checkEmailExistOrNOt = (email) => {
    setisLoading(true);
    ApiService.checkEmail(email)
      .then((res) => {
        if (res.errorCode === 200) {
          setCurrentStep(2);
        } else {
          toast.error(res?.errorMessage)
        }
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(false);
        toast.error(error?.message);
      });
  }
  const checkMobileNoExistOrNOt = (Mob) => {
    setisLoading(true);
    ApiService.checkMobileNo(Mob)
      .then((res) => {
        if (res.message === "") {
          setCurrentStep(3);
        } else {
          toast.error(res?.message)
        }
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(false);
        toast.error(error?.message);
      });
  }

  
  const handleNext = (e) => {
    e.preventDefault();
    if (currentStep === 1) {
      if (form.email && form.password && form.cpassword) {
        checkEmailExistOrNOt(form.email);
      }
    } else if (currentStep === 2) {
      if (form.fname && form.lname && form.contact && form.gender && form.dob) {
        checkMobileNoExistOrNOt(form.contact)
        // setCurrentStep(3);
        setPhoneNumber(form.contact);
      }
    }
    // just for checking next step
    else if (currentStep === 3) {
      if (form.email || phoneNumber) {
        console.log(authorizationMethod)
        if (otpType === "phone") {
          setOtp("");
          sendAuthOtp(phoneNumber);
        }
        else if(otpType === "email"){
          setOtp("");
          sendEmailAuthOtp(form.email);
        }
        countdown();
        setCurrentStep(4);
      }
    } else if (currentStep === 4 && otp?.length === 6) {
      if (otpType === "phone") {
        validateAuthOtp(otpDetails?.Details, otp);
      }
      else if (otpType === "email") {
        validateEmailAuthOtp(otpEmailDetails?.response, otp);
      }
    }
  };



  const handleBack = (e) => {
    e.preventDefault();
    if (currentStep === 2) {
      setCurrentStep(1);
    }
    if (currentStep === 3) {
     
      setCurrentStep(2);
    }
    if (currentStep === 4) {
      setTimeLeft("");
      setCurrentStep(3);
    }
  };

  // all authorizaiton logic here

  // changing the authorization as per the check on email or phone
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAuthorizationMethod(e.target.value);
    if (value === 'email') {
      setOtpType("email"); // Store email if email is selected
    } else if (value === 'phone') {
      setOtpType("phone"); // Store phone number if phone is selected
    }
  };

  const getOtp = (otp) => {
    setInvalidOtp(false);
    setOtp(otp);
  };
  const backOnAuthentication = () => {
    setTimeLeft(0);
    setCurrentStep(currentStep - 1);
    setInvalidOtp(false);
    setOtp("");
  };


  const countdown = () => {
    let intervalId;
    let timeLeft = otpType === "email" ? 30 : 30;
    clearInterval(intervalId);
    setTimeLeft(timeLeft);
    intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(intervalId);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };
  
  const getCountryCode = () => {
    setisLoading(true);
    ApiService.countryList()
      .then((res) => {
        setCountryCode(res);
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(false);
        toast.error(error?.message);
      });
  }
  useEffect(() => {
    getCountryCode()
  }, []);

  const getMaxDate = () => {
    const today = new Date();
    today.setDate(today.getDate() - 1);  // Set max date to yesterday
    return today.toISOString().split('T')[0];  // Format as yyyy-mm-dd
  };

  const handlePhoneNumber = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\D/g, '');
    setPhoneNumber(sanitizedValue);
    const errorMsg = validateField(name, sanitizedValue);
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [name]: errorMsg,
    }));
  }
  // for getting mobile otp details
  const sendAuthOtp = (number) => {
    setisLoading(true);
    ApiService.getMobOtp(number)
      .then((res) => {
        setisLoading(false);
        setOtpDetails(res.data);
        setVerified(false);

        countdown();
        setOtp("");
        setInvalidOtp(false);
      })
      .catch((error) => {
        setisLoading(false);
        toast.error(error?.message);
      });
  };

  
  // for validate mobile otp details
  const validateAuthOtp = (session, otp) => {
    setisLoading(true);
    ApiService.validateMobOtp(session, otp)
      .then((res) => {
        setisLoading(false);
        setVerified(true);
        otpSubmit();
        // countdown();
        setOtp("");
        setInvalidOtp(false);
      })
      .catch((error) => {
        setisLoading(false);
        setInvalidOtp(true);
        toast.error(error?.Details);
      });
  };
  
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    if (country.CountryCode === "91") {
      setOtpVerificationPossible(true);
      console.log(otpVerificationPossible);
  } 
  else{
    setOtpVerificationPossible(false);
  }
    setDropdownOpen(false);
    setForm((prevForm) => ({
      ...prevForm,
      countryCode: country.CountryCode
    }));
  };

  // for getting email otp details
  const sendEmailAuthOtp = (email) => {
    setisLoading(true);
    const data = {
      source: email,
      sourceType: "email",
    };
    ApiService.getEmailOtp(data)
      .then((res) => {
        setisLoading(false);
        setOtpEmailDetails(res);
        setVerified(false);

        countdown();
        setOtp("");
        setInvalidOtp(false);
      })
      .catch((error) => {
        setisLoading(false);
        toast.error(error?.message);
      });
  };
  const maskEmail = (email) => {
    const [localPart, domain] = email.split("@");
    
    if (localPart.length > 1) {
      const maskedLocalPart = localPart[0] + "*".repeat(localPart.length - 1);
      return `${maskedLocalPart}@${domain}`;
    }
    return email;
  };
  
  // for validating email otp
const validateEmailAuthOtp = (session, otp) => {
  
  const data = {
    sessionId: session,
    authCode: otp,
  };
  setisLoading(true);
  ApiService.validateEmailOtp(data)
    .then((res) => {
      setisLoading(false);
      setVerified(true);
      otpSubmit();
      // countdown();
      setOtp("");
      setInvalidOtp(false);
    })
    .catch((error) => {
      setisLoading(false);
      setInvalidOtp(true);
      toast.error(error?.message);
    });
};


  // for resend otp/
  const resendOtp = () => {
    if (otpType === "phone") {
      setOtp("");
      sendAuthOtp(phoneNumber);
    }
    else if(otpType === "email"){
      setOtp("");
      sendEmailAuthOtp(form.email);
    }
    countdown();
    setInvalidOtp(false);
  };

  const otpSubmit = () => {
    setInvalidOtp(false);
    setOtpSubmitted(true);
    handleSubmit();
    console.log(verified);
  };

  return (
    <div className={classes["main-container"]}>
      <div className={`${classes.row_hieght} row`}>
        <div className={`${classes.col_img} col-md-7`}>
          <div>
            <img className={classes["left-img"]} src={leftImg} alt="" />
          </div>
        </div>
        <div className={`${classes.right_col} col-md-5 white-back`}>
          <div className={classes["sign_up-box"]}>
            {/* {(currentStep != 1) && (
              <div title="back" onClick={handleBack} className={classes["back-btn"]}>
              <img className={classes["back-arrow-icon"]} src={backArrow} alt="" />
            </div>
            )} */}
            <div className={classes.header}>
              <span>
                <img src={union} alt="" />
              </span>
              <span>MSM Pursuit</span>
            </div>

            <form>
              <div className={classes["form-section"]}>
                {/* step1 */}

                {currentStep === 1 && (
                  <div className={classes["form-content"]}>
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputEmail1"
                        className={`${classes.label} form-label`}
                      >
                        Email <span className="required">*</span>
                      </label>
                      <input
                        onChange={changeHandler}
                        type="text"
                        placeholder="Type Email Here..."
                        name="email"
                        value={form.email}
                        onBlur={changeHandler}
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                      {formErrors.email && (
                        <span className={classes.error}>
                          {formErrors.email}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputPassword1"
                        className={`${classes.label} form-label`}
                      >
                        Password <span className="required">*</span>
                      </label>

                      <div className={classes["input-with-icon"]}>
                        <input
                          onChange={changeHandler}
                          onBlur={changeHandler}
                          type={passwordType}
                          placeholder="Type Password Here..."
                          name="password"
                          value={form.password}
                          className="form-control "
                          id="exampleInputPassword1"
                        />
                        {form.password && (
                          <div className={classes["icon-container"]}>
                            <span
                              className={classes["eyeicon"]}
                              onClick={viewPass}
                            >
                              <i
                                className={`fa ${visible ? "fa-eye" : "fa-eye-slash"
                                  }`}
                              />
                            </span>
                          </div>
                        )}
                      </div>

                      {formErrors.password && (
                        <span className={classes.error}>
                          {formErrors.password}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputConfirmPassword"
                        className={`${classes.label} form-label`}
                      >
                        Confirm Password <span className="required">*</span>
                      </label>
                      <div className={classes["input-with-icon"]}>
                        <input
                          onChange={changeHandler}
                          onBlur={changeHandler}
                          type={confirmPasswordType}
                          placeholder="Re-type Password Here..."
                          name="cpassword"
                          value={form.cpassword}
                          className="form-control"
                          id="exampleInputConfirmPassword"
                        />
                        {form.cpassword && (
                          <div className={classes["icon-container"]}>
                            <span
                              className={classes["eyeicon"]}
                              onClick={viewConfirmPass}
                            >
                              <i
                                className={`fa ${visibleConfirm ? "fa-eye" : "fa-eye-slash"
                                  }`}
                              />
                            </span>
                          </div>
                        )}
                      </div>

                      {formErrors.cpassword && (
                        <span className={classes.error}>
                          {formErrors.cpassword}
                        </span>
                      )}
                    </div>

                    <div className={classes["password-validation"]}>
                      <p className={classes["validation-heading"]}>
                        Password must contain:
                      </p>

                      <ul className={classes["validation-item"]}>
                        {!form.password ? (
                          <li>
                            <span>At least 8 characters</span>
                          </li>
                        ) : !passwordValidationErrors.minLength ? (
                          <li style={{ color: "green" }}>
                            <div className="row">
                              <span className="col-9">
                                At least 8 characters
                              </span>
                              <span className="col-3">
                                <i className="fa fa-check" />
                              </span>
                            </div>
                          </li>
                        ) : (
                          <li style={{ color: "red" }}>
                            <div className="row">
                              <span className="col-9">
                                At least 8 characters
                              </span>
                              <span className="col-3">
                                <i className="fa fa-close" />
                              </span>
                            </div>
                          </li>
                        )}

                        {!form.password ? (
                          <li>
                            <span>At least 1 special character</span>
                          </li>
                        ) : !passwordValidationErrors.specialChar ? (
                          <li style={{ color: "green" }}>
                            <div className="row">
                              <span className="col-9">
                                At least 1 special character
                              </span>
                              <span className="col-3">
                                <i className="fa fa-check" />
                              </span>
                            </div>
                          </li>
                        ) : (
                          <li style={{ color: "red" }}>
                            <div className="row">
                              <span className="col-9">
                                At least 1 special character
                              </span>
                              <span className="col-3">
                                <i className="fa fa-close" />
                              </span>
                            </div>
                          </li>
                        )}

                        {!form.password ? (
                          <li>
                            <span>At least 1 number</span>
                          </li>
                        ) : !passwordValidationErrors.number ? (
                          <li style={{ color: "green" }}>
                            <div className="row">
                              <span className="col-9">At least 1 number</span>
                              <span className="col-3">
                                <i className="fa fa-check" />
                              </span>
                            </div>
                          </li>
                        ) : (
                          <li style={{ color: "red" }}>
                            <div className="row">
                              <span className="col-9">At least 1 number</span>
                              <span className="col-3">
                                <i className="fa fa-close" />
                              </span>
                            </div>
                          </li>
                        )}

                        {!form.password ? (
                          <li>
                            <span>At least 1 uppercase letter</span>
                          </li>
                        ) : !passwordValidationErrors.uppercase ? (
                          <li style={{ color: "green" }}>
                            <div className="row">
                              <span className="col-9">
                                At least 1 uppercase letter
                              </span>
                              <span className="col-3">
                                <i className="fa fa-check" />
                              </span>
                            </div>
                          </li>
                        ) : (
                          <li style={{ color: "red" }}>
                            <div className="row">
                              <span className="col-9">
                                At least 1 uppercase letter
                              </span>
                              <span className="col-3">
                                <i className="fa fa-close" />
                              </span>
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                )}

                {/* step 2*/}

                {currentStep === 2 && (
                  <div className={classes["form-content"]}>
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputFirstName"
                        className={`${classes.label} form-label`}
                      >
                        First Name <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Type Name Here..."
                        name="fname"
                        value={form.fname}
                        onChange={changeHandler}
                        onBlur={changeHandler}
                        className="form-control"
                        id="exampleInputFName"
                      />
                      {formErrors.fname && (
                        <span className={classes.error}>
                          {formErrors.fname}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputLastName"
                        className={`${classes.label} form-label`}
                      >
                        Last Name <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Type Name Here..."
                        name="lname"
                        value={form.lname}
                        onChange={changeHandler}
                        onBlur={changeHandler}
                        className="form-control"
                        id="exampleInputLName"
                      />
                      {formErrors.lname && (
                        <span className={classes.error}>
                          {formErrors.lname}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputLastName"
                        className={`${classes.label} form-label`}
                      >
                        Contact <span className="required">*</span>
                      </label>
                      <div className={`${classes.DropDownCountryCode} row`}>
                        <div
                          className={`${classes.dropDownSection} col-md-3`}
                          onClick={toggleDropdown}
                        >
                          <div >
                            <img className={classes.iconcode} src={dropdownOpen ? upperDrop : dropDown} alt="dropdown icon" />
                          </div>
                          {selectedCountry ? (
                            <div>
                              <span
                                className={`fi fi-${selectedCountry.CShortName.toLowerCase()}`}
                                style={{ marginRight: '8px' }}
                              ></span>
                              {`+${selectedCountry.CountryCode}`}
                            </div>
                          ) : (
                            "Select Country Code"
                          )}
                        </div>
                        {dropdownOpen && (
                          <ul className={`${classes.dropdownMenu}`}>
                            {countryCode.map((rowData, index) => (
                              <li
                                key={index}
                                className={classes.dropdownItem}
                                onClick={() => handleCountrySelect(rowData)}
                              >
                                <span
                                  className={`fi fi-${rowData.CShortName.toLowerCase()}`}
                                  style={{ marginRight: '8px' }}
                                ></span>
                                {`+${rowData.CountryCode} - ${rowData.CountryName}`}
                              </li>
                            ))}
                          </ul>
                        )}
                        <div className={`${classes.dropDownSection} col-md-9`}>
                          <input
                            type="tel"
                            placeholder="(XXX)-XXX-XXXX"
                            name="contact"
                            value={form.contact}
                            onChange={changeHandler}
                            onBlur={changeHandler}
                            className={`form-control`}
                            id="exampleInputContact"
                          />
                        </div>
                      </div>
                      {formErrors.contact && (
                        <span className={classes.error}>{formErrors.contact}</span>
                      )}
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="exampleInputLastName"
                        className={`${classes.label} form-label`}
                      >
                        Gender <span className="required">*</span>
                      </label>
                      <div className={classes.dropDownIconContainer}>
                      <select
                        name="gender"
                        value={form.gender || ""}
                        className="form-control"
                        onChange={changeHandler}
                        onFocus={handleFocus(setGenderOpen)}
                        onBlur={handleBlur(setGenderOpen)}
                        id="exampleInputContact"
                      >
                        <option value="" disabled>----select----</option>
                        {GenderData.map((data, index) => (
                          <option value={data}>{data}</option>
                        ))}
                      </select>

                      <div className={classes.DropDownIconGender}>
                      <img className={classes.icon} src={isGenderOpen ? upperDrop:dropDown  } alt="dropdown icon" />
                       </div> 
                      </div>
                      {formErrors.gender && (
                        <span className={classes.error}>
                          {formErrors.gender}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputLastName"
                        className={`${classes.label} form-label`}
                      >
                        Birthday <span className="required">*</span>
                      </label>
                      <div className={classes.dropDownIconContainer}>
                        <input
                          type="date"
                          placeholder="Type Name Here..."
                          name="dob"
                          value={form.dob}
                          onChange={changeHandler}
                          onBlur={changeHandler}
                          className="form-control"
                          id="exampleInputDob"
                          max={getMaxDate()}
                        />
                      </div>
                      {formErrors.dob && (
                        <span className={classes.error}>
                          {formErrors.dob}
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {/* step 3 */}

               {currentStep === 3 && (
                  <>
                    <div className={classes["authorization-container"]}>
                      <div>
                        <p className={classes["heading"]}>
                          Account Authorization:
                        </p>
                      </div>
                      <div className={classes["authorization-content"]}>
                        <div className={classes["authorization-option"]}>
                          <input
                            className={`${classes.radioInput}`}
                            type="radio"
                            id="email"
                            name="authorization"
                            value="email"
                            checked={authorizationMethod === "email"}
                            onChange={handleChange}
                          />
                          <label className="form-check-label" htmlFor="email">
                            <span>Email : </span>
                            <span>{maskEmail(form.email)}</span>
                          </label>
                        </div>
                        <div className={classes["authorization-option"]}>
                          <input
                            className={`${classes.radioInput}`}
                            type="radio"
                            id="phone"
                            name="authorization"
                            value="phone"
                            checked={otpVerificationPossible && authorizationMethod === "phone"}
                            onChange={handleChange}
                            disabled={!otpVerificationPossible}
                          />
                          <label
                            className={`${classes.phone_section} form-group form-check-label `}
                            htmlFor="phone"
                          >
                            <span>Phone : </span>
                            <span className="form-group">
                              <input
                                type="tel"
                                className="form-control"
                                name="phoneNumber"
                                placeholder="Type phone number here..."
                                value={phoneNumber}
                                onChange={handlePhoneNumber}
                                // disabled={authorizationMethod !== "phone"}
                                disabled={true}
                              />
                              {formErrors.phoneNumber && (
                                <span className={classes.error}>
                                  {formErrors.phoneNumber}
                                </span>
                              )}
                            </span>
                          </label>
                        </div>
                      </div>
                      {(form.email || phoneNumber) && (
                        <p className={classes["verify-message"]}>
                          We will send you a code to verify your account and to
                          keep it secure.
                        </p>
                      )}
                    </div>
                  </>
                )}

                {currentStep === 4 && (
                  <>
                    <div className={classes["otp_section"]}>
                      <div>
                        <p className={classes["heading"]}>
                          Account Authorization:
                        </p>
                      </div>
                      <div className={classes["otp_input"]}>
                        <OTPInput
                          inputStyle={invalidOtp ? invalidOtpStyle : inputStyle}
                          value={otp}
                          onChange={getOtp}
                          numInputs={6}
                          renderSeparator={<span></span>}
                          renderInput={(props) => (
                            <input
                              className={classes["otp_Input"]}
                              {...props}
                            />
                          )}
                        />
                      </div>
                      <div>
                        {invalidOtp && (
                          <p className={classes["timer"]}>
                            Wrong code. Please try again
                          </p>
                        )}
                        {otpSubmitted && (
                          <p className={classes["timer"]}>Success!</p>
                        )}
                      </div>
                      <div>
                        {timeLeft > 0 ? (
                          <p className={classes["timer"]}>{timeLeft} seconds</p>
                        ):<br/>}
                      </div>
                      <div>
                        <button
                          onClick={resendOtp}
                          type="button"
                          disabled={timeLeft !== 0}
                          className={`${classes.primary_button} primary-button`}
                        >
                          Resend Code
                        </button>
                      </div>
                      <div>
                        <p
                          onClick={backOnAuthentication}
                          className={classes["change_auth"]}
                        >
                          Change Authentication Device
                        </p>
                      </div>
                    </div>
                  </>
                )}

                <div className="mt-2">
                  <button
                    disabled={
                      (currentStep === 1 &&
                        (!form.email || !form.password || !form.cpassword || (passwordValidationErrors.minLength) || (passwordValidationErrors.specialChar) || (passwordValidationErrors.number) || (passwordValidationErrors.uppercase) || (form.password != form.cpassword))) ||
                      (currentStep === 2 && (!form.fname || !form.lname || !form.contact || !form.countryCode || !form.gender || !form.dob)) ||
                      (currentStep === 4 && otp?.length < 6) || Object.keys(formErrors).some(
                        (key) => formErrors[key])
                    }
                    className={`${classes.primary_button} primary-button`}
                    onClick={handleNext}
                  >
                    Next
                  </button>
                  {(currentStep != 1) && (
               <button title="back" onClick={handleBack} className={`${classes.secondary_button} secondary-button mt-2`}>Back</button>
            )}
                  
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>{isLoading && <Loader />}</div>
    </div>
  );
}

export default SignUp;

import React, { useEffect, useRef, useState } from "react";
import ProfileCard from "./ProfileCard";
import classes from "./Common.module.css";
import ConnectionService from "../../../../../../Services/ConnectionService";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../../../GlobleShared/Modal/ConfirmationModal/ConfirmationModal";
import Loader from "../../../../../GlobleShared/Loader/Loader";
import useDebounce from "../../../../../GlobleShared/CustomHooks/useDebounce";

const Suggestions = ({searchInput, isSearch, setIsSearch}) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [paginated, setPaginated] = useState(true);
  const [profiles, setProfiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState(null);
  const [isLoading, setLsLoading] = useState(false);
  const [searchTerm, setsearchTerm] = useState("");
  const [confirmationText, setConfirmationText] = useState('')
  const [receiverId, setReceiverId] = useState();
  const [totalCount, setTotalCount] = useState();
  const initialized = useRef(false);
  const debouncedSearchInput = useDebounce(searchInput, 300);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      getInvitationLIst(page);
    }

    if (debouncedSearchInput.length >= 3) {
      refreshInvitationLIst(page, debouncedSearchInput)
    } 
    else if (debouncedSearchInput.length <= 2 && isSearch) {
      refreshInvitationLIst(page, "");
      setIsSearch(false);
    }
  }, [debouncedSearchInput]);

  const getInvitationLIst = (pageNum) => {
    let data = {
      limit: limit,
      page: pageNum,
      paginated: paginated,
      searchTerm: "",
    };
    setLsLoading(true)
    ConnectionService.getSuggestions(data)
      .then((res) => {
        setProfiles([...profiles,...res.list]);
        setTotalCount(res?.totalCount)
        setLsLoading(false)
      })
      .catch((error) => {
        console.log('error: ', error);
        setLsLoading(false)
      });
  }

  const refreshInvitationLIst = (pageNum, searchInput) => {
    let data = {
      limit: limit*pageNum,
      page: 1,
      paginated: paginated,
      searchTerm: searchInput,
    };
    setLsLoading(true)
    ConnectionService.getSuggestions(data)
      .then((res) => {
        setProfiles([...res.list]);
        setTotalCount(res.totalCount)
        setLsLoading(false)
      })
      .catch((error) => {
        console.log('error: ', error);
        setLsLoading(false)
      });
  }

  const updateStatus = (receiverIdValue, statusId) => {
    let successMsg;
    let data = {
      requesterId: receiverIdValue,
      connectionStatusId: statusId,
    };
    setLsLoading(true)
    ConnectionService.addConnection(data)
      .then((res) => {
        refreshInvitationLIst(page);
        setLsLoading(false);
        if(res?.message && statusId === 1){
          successMsg = "Your invitation has been sent successfully!";
        }
        if(res?.message && statusId === 3){
          successMsg = "";
        }
        toast.success(successMsg);
      })
      .catch((error) => {
        console.log('error: ', error);
        setLsLoading(false)
      });
  };

  const handleAction = (receiverId, action) => {
    setReceiverId(receiverId);
    setModalAction(action);
    setConfirmationText(`Are you sure you want to ${action.toLowerCase()} with the selected contact?`);
    setShowModal(true);
  };
  const handleConfirmation = () => {
    if (modalAction === "Connect") {
      updateStatus(receiverId, 1);
    } else if (modalAction === "Dismiss") {
      updateStatus(receiverId, 3);
    }
    setShowModal(false);
  };
  const closeModalHandler = () => {
    setShowModal(false);
    setConfirmationText("");
    setModalAction("");
  }

  const viewMore = () => {
    getInvitationLIst(page +1);
    setPage(page +1);
  }

  return (
    <>
    {showModal && <ConfirmationModal
        confirmationText={confirmationText}
        onShow={handleAction}
        onClose={closeModalHandler}
        getConfirmation={handleConfirmation}
        btnText={modalAction}
      />}

      {profiles.length > 0 && (
        <div className={classes.containers}>
          {profiles.map((profile) => (
            <ProfileCard
              key={profile.rowNum}
              connectionsProfileDetails={profile}
              buttonShow={true}
              handleAction={handleAction}
              ConnectButtonShow={true}
            />
          ))}
        </div>
      )}

      {(totalCount > profiles?.length && profiles.length > 0) && (
        <div className="text-center mt-2 mb-2">
          <button onClick={viewMore} className={classes['view-more']}>View more</button>
        </div>
      )}

      {profiles.length === 0 && (
        <div className={classes["no-data"]}>
          <p>
            No Suggestions Found
          </p>
        </div>
      )}

      {isLoading && (
        <Loader></Loader>
      )}
    </>
  );
};

export default Suggestions;
